<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div class="flex items-center gap-10">
        <div v-html="formattedActivityText" class=""></div>
        <div
          v-if="hasTransactionDetails"
          @click="expandTransactionDetails = !expandTransactionDetails"
        >
          <font-awesome-icon
            :icon="[
              'fal',
              expandTransactionDetails ? 'chevron-down' : 'chevron-right'
            ]"
            class="text-blue-700 hover:text-blue-400 mr-2"
          />
        </div>
      </div>

      <div v-if="expandTransactionDetails">
        <div
          class="flex items center gap-5"
          v-for="(key, index) in Object.keys(transactionDetailsMapper)"
          :key="index"
        >
          <p class="font-medium w-24 text-left">
            {{ transactionDetailsMapper[key] }}:
          </p>
          <p
            v-if="['paymentAmount', 'totalAmount'].includes(key)"
            class="text-right"
          >
            {{ formatAmount(transactionData[key]) }}
          </p>
          <p v-else>
            <span>{{ transactionData[key].toUpperCase() }}</span>
          </p>
        </div>
      </div>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
import { formatCurrencyFloat } from "@/plugins/filters";
export default {
  mixins: [ActivitiesTimelineMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    transaction: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      expandTransactionDetails: false,
      transactionDetailsMapper: {
        paymentAmount: "Amount paid",
        totalAmount: "Total Amount",
        policyPrefix: "Policy prefix",
        transactionPolicyNumber: "Policy number",
        cardType: "Card Type",
        paymentCategory: "Payment type",
        status: "Payment type"
      }
    };
  },
  methods: {
    async generateReadableUpdateQuote() {
      let contentHtml = ``;
      for (let update of this.updates) {
        contentHtml = this.generateRegularActivityString(contentHtml, update);
      }
      this.setReadableUpdateProps(contentHtml);
    },
    formatAmount(amount) {
      return formatCurrencyFloat(amount);
    }
  },
  computed: {
    hasTransactionDetails() {
      return Boolean(
        this.activity?.data?.transactionData &&
          Object.keys(this.activity?.data?.transactionData).length
      );
    },
    transactionData() {
      return this.activity?.data?.transactionData || {};
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "ADD_TRANSACTION_SUCCESS": {
            const insuredName =
              this.activity.data?.transactionData?.customerName || "N/A";
            this.generateReadableGeneric({
              content: `A transaction was recorded on behalf of ${insuredName}`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "ADD_TRANSACTION_FAILURE":
            this.generateReadableFailGeneric({
              content: "Failed to record transaction.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>
