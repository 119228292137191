
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import TransactionsActivitiesTimelineItem from "./TransactionsActivitiesTimelineItem.vue";
interface ITransactionActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "transactionsActivitiesView",
  components: { TransactionsActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): ITransactionActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
        ADD_TRANSACTION_SUCCESS,
          ADD_TRANSACTION_FAILURE
          `.replace(/\s/g, ""),
        activityType: "TransactionActivity"
      };
    }
  }
});
